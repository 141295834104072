<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 15 2"
        fill="none"
    >
        <path fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.9248 1c0 .41421-.3358.75-.75.75h-13C.760591 1.75.424805 1.41421.424805 1c0-.414214.335786-.75.749995-.75h13c.4142 0 .75.335786.75.75Z"
            fill="currentColor"
        />
    </svg>
)
</script>
